import React from 'react'

import EventList from '../components/eventList'

export default class EventPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
  }

  componentDidMount() {
    try {
      const WOW = require('wowjs')
      const wow = new WOW.WOW()
      wow.init()
    } catch (e) {
      console.error(e)
    }
  }

  render() {
    return (
      <div>
        <section className="section-top">
          <div className="container">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="section-top-title">
                <h2>Eventos</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12" />
          </div>
        </section>
        <div className="mbta">
          <EventList listMode="true" />
        </div>
      </div>
    )
  }
}
